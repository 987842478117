import { FunctionComponent } from "react";
import "./Popup.scss";

interface PopupProps {
  show: boolean;
  close?: () => void;
}

const Popup: FunctionComponent<PopupProps> = (props) => {
  if (!props.show) return null;

  const close = () => {
    if (props.close) props.close();
  };

  return (
    <div
      className={"popUp"}
      onClick={(e) => {
        e.stopPropagation();
        if (e.target === e.currentTarget) close();
      }}
    >
      <div className={"popUpModale"}>
        {props.close && (
          <button className={"closeButton"} onClick={() => props.close()}>
            X
          </button>
        )}
        <div className={"popUpContent"}>{props.children}</div>
      </div>
    </div>
  );
};

export default Popup;
