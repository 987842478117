import { FunctionComponent } from "react";
import "./DefaultLayout.scss";

interface DefaultLayoutProps {
  headerLeft?: JSX.Element;
  header?: JSX.Element;
  headerCenter?: JSX.Element;
  headerRight?: JSX.Element;
  bodyLeft?: JSX.Element;
  bodyCenter?: JSX.Element;
  bodyRight?: JSX.Element;
  footerLeft?: JSX.Element;
  footerCenter?: JSX.Element;
  footerRight?: JSX.Element;
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = (props) => {
  return (
    <div className={"defaultlayout_layout"}>
      <header className={`shadow defaultlayout_header`}>{props.header}</header>
      <section className={"defaultlayout_body"}>
        {props.bodyLeft && (
          <aside className={`shadow defaultlayout_bodyLeft`}>
            {props.bodyLeft}
          </aside>
        )}
        <main className={"defaultlayout_bodyCenter"}>{props.bodyCenter}</main>
        {props.bodyRight && (
          <aside className={`shadow defaultlayout_bodyRight`}>
            {props.bodyRight}
          </aside>
        )}
      </section>
      {(props.footerLeft || props.footerCenter || props.footerRight) && (
        <footer className={"defaultlayout_footer"}>
          <div className={"defaultlayout_footerLeft"}>{props.footerLeft}</div>
          <div className={"defaultlayout_footerCenter"}>
            {props.footerCenter}
          </div>
          <div className={"defaultlayout_footerRight"}>{props.footerRight}</div>
        </footer>
      )}
    </div>
  );
};

export const Layout: FunctionComponent<DefaultLayoutProps> = (props) => {
  return <div className={"defaultlayout_layout"}>{props.children}</div>;
};
export const Header: FunctionComponent<DefaultLayoutProps> = (props) => {
  return <header className={`defaultlayout_header`}>{props.children}</header>;
};

export const Body: FunctionComponent<DefaultLayoutProps> = (props) => {
  return <section className={"defaultlayout_body"}>{props.children}</section>;
};

export const Left: FunctionComponent<DefaultLayoutProps> = (props) => {
  return (
    <aside className={`shadow defaultlayout_bodyLeft`}>{props.children}</aside>
  );
};

export const Center: FunctionComponent<DefaultLayoutProps> = (props) => {
  return <main className={"defaultlayout_bodyCenter"}>{props.children}</main>;
};
export const Right: FunctionComponent<DefaultLayoutProps> = (props) => {
  return (
    <aside className={`shadow defaultlayout_bodyRight`}>{props.children}</aside>
  );
};

export default DefaultLayout;
