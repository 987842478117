//@ts-ignore
import { notif } from "@ledr/layout";
import { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Signup, Confirm, Login, Forgot, Reset } from "../../hoc/login";
import { Switch, Route } from "react-router-dom";
import Popup from "../../components/utilities/Popup";
import Avial from "@ledr/ts-client"
import {
	loginAction,
	logoutAction,
	userChangeKeychainAction,
	userAddKeychainAction
} from "../../store/user/actions";
import { Layout } from "../../components/layout/DefaultLayout";
import {AuthInput} from "@ledr/instruments";

const isStandAlone = true;
interface logProps{
	loginMode: "standalone" | ""
	auth: string
}
const Browser: FunctionComponent<logProps> = (props) => {
	const [auth, setAuth] = useState(
		props.auth ||
		"00000000-0000-0000-0000-000000000000"
	);
	console.log(auth, props.auth)

	const dispatch = useDispatch();
	let standaloneLog = () => {

		let newKeychain = {
			firstName: '-FirstName-',
			lastName: '-LastName-',
			email: 'standalone',
			identity: '<0|0|0>',

			keychain: {
				identity: "Standalone",
				current : 0,
				accesses: [{
					compartment: "Standalone",
					token: auth,
				}]
			},
		};

		dispatch(loginAction(newKeychain));
		notif.success("logged");

	}

  return (
    <Layout>
      <Popup show>
				{props.loginMode !== "standalone"
					?
					(
        <Switch>
          <Route path="/signup">
            <Signup />
          </Route>

          <Route path="/forgot">
            <Forgot />
          </Route>

          <Route exact path="/resetPassword/:email/:token" children={<Reset />} />
          <Route exact path="/confirm/:email/:token" children={<Confirm />} />

          <Route>
            <Login />
          </Route>

        </Switch>
					) : (
						<div style={{textAlign: "center"}}>
							<h1 style={{margin: "0px"}}>Auth</h1>
							<div style={{margin: "10px"}} >
								<AuthInput
									value={ new Avial.Values.V_Authorization(props.auth ?? auth) }
									onChange={(value) => setAuth(value.toString())}
								/>
							</div>

							<button onClick={standaloneLog}>
								GO
							</button>

						</div>
					)
				}


      </Popup>
    </Layout>
  );
};

export default Browser;
