import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import "./Login.scss";

interface LoginFormProps {
  login: any;
}

const LoginForm: FunctionComponent<LoginFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  return (
    <div className={"login"}>
      <h1>Maestro</h1>
      <input
        id="email"
        name="email"
        type="email"
        placeholder={"Email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        id="password"
        name="password"
        type="password"
        placeholder={"Password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <div className={"rememberForgot"}>
        <div className={"remember"}>
          <input
            type="checkbox"
            checked={remember}
            onChange={() => setRemember(!remember)}
            disabled={true}
          />
          Remember me
        </div>
      </div>
      <button
        id={"loginButton"}
        className={"loginButton"}
        onClick={() => {
          props.login(email, password);
        }}
      >
        Login
      </button>

      <hr />

      <div className={"newAccountButton"}>
        <Link to={"/signup"}>Sign up</Link>
      </div>

      <div className={"forgot"}>
        <Link
          to={{
            pathname: `/forgot`,
            search: email.length ? `?email=${email}` : "",
          }}
        >
          Forgot Password ?
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
