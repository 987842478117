//@ts-ignore
import { notif } from "@ledr/layout";
import { NavLink } from "react-router-dom";
import { Icon, Tooltip } from "@ledr/instruments";
import Popup from "../components/utilities/Popup";


import { useState, useContext } from "react";
import ApiContext from "../context/api";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
	loginAction,
	logoutAction,
	userChangeKeychainAction,
	userAddKeychainAction
} from "../store/user/actions";

import LoginForm from "../components/form/Login";
import SignupForm from "../components/form/Signup";
import ForgotPassword from "../components/form/ForgotPassword";
import AccountMenu from "../components/menu/Account";
import ResetPassword from "../components/form/ResetPassword";
import { AppState } from "../store/types";

export const Login = () => {
	const api = useContext(ApiContext);
	const dispatch = useDispatch();

	const loginCB = (email: string, password: string) => {
		api.login(email, password).then(
			(response: any) => {
				console.log(response)
				dispatch(loginAction(response));
				notif.success("logged");
				api.panels.load()
					.then(() => {
						notif.success("Panels Loaded");
					});
			},
			(err) => {
				console.error(err)
				notif.error(err.message);
			}
		);
	};

	return <LoginForm login={loginCB} />;
};

export const Signup = () => {
	const api = useContext(ApiContext);
	let history = useHistory();

	const signupCb = (
		firstName: string,
		lastName: string,
		email: string,
		password: string
	) => {
		api.signup(firstName, lastName, email, password).then(
			(response: any) => {
				notif.success(response.message);
				history.push("");
			},
			() => {}
		);
	};

	return <SignupForm signup={signupCb} />;
};

export const Confirm = () => {
	const api = useContext(ApiContext);
	let history = useHistory();
	let { email, token } = useParams<Record<string, string | undefined>>();
	api.confirmEmail(email, token).then(
		(response: any) => {
			notif.success(response.message);
			history.push("");
		},
		() => {}
	);

	return (
		<div>
			<h1>Confirming...</h1>
			<br />
			{email}
			<br />
			{token}
		</div>
	);
};

export const Forgot = () => {
	const api = useContext(ApiContext);
	let location = useLocation();
	let history = useHistory();

	let searchParams = new URLSearchParams(location.search);
	let email = searchParams.has("email") ? searchParams.get("email") : undefined;

	const sendForgotPasswordMail = (email: string) =>
		api.forgotPassword(email).then(
			(response: any) => {
				notif.success(response.message);
				history.push("");
			},
			() => {}
		);
	return <ForgotPassword email={email} onSubmit={sendForgotPasswordMail} />;
};

export const Reset = () => {
	const api = useContext(ApiContext);
	let history = useHistory();

	let { email, token } = useParams<Record<string, string | undefined>>();
	const sendResetPassword = (password: string) =>
		api.resetPassword(email, token, password).then(
			(response: any) => {
				notif.success(response.message);
				history.push("");
			},
			() => {}
		);
	return <ResetPassword onSubmit={sendResetPassword} />;
};

export const Account = () => {
	const { user, keychain, endpoints } = useSelector((state: AppState) => ({
		user: state.user,
		keychain: state.user.keychain,
		endpoints: state.endpoints,
	}));
	const [isPopup, setIsPopup] = useState(false);
	const [name, setName] = useState("");
	const [token, setToken] = useState("");

	const dispatch = useDispatch();
	const onLogout = () => dispatch(logoutAction());
	return (
		<>

			{endpoints.map((e,i) =>

				<div
					key={i}
					style={{
					margin: "10px 0px",
					padding: "5px 10px",
					display: "inline-block",
					border: "1px solid rgba(255, 255, 255, 0.1)",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					borderRadius: "4px",
					color: "grey"
					}}
				>
			<Tooltip
				position="bottom"
				//@ts-ignore
				text={ <> host : {e.host} </> }>
				{/*
				// @ts-ignore */}
				<>
					{
					//@ts-ignore
						e?.channels?.map((e,i) => 
						<div
							key={i}
							style={{
							display: "inline-block",
							marginRight: "5px",
							width: "10px",
							height: "10px",
								backgroundColor: e?.open ? e?.in_use ? "chocolate" : "green" : "red",
							}}>
						</div>
				)
					}
				{e.name.toUpperCase()}
				</>
				</Tooltip>
				</div>
			)}

			<select
				style={{margin: "10px"}}
				value={keychain?.current?.toString()}
				onChange={
					(e)=>
					{
						let value = Number(e.target.value)
						if (value !== -1)
							dispatch(userChangeKeychainAction(Number(e.target.value)))
						else
							setIsPopup(true)

					}
				}

			>
			{keychain?.accesses?.map((e, i) =>
				<option
					key={i}
					value={i.toString()}>
					{e.compartment}
				</option>
			)}

				<option value={-1}>
					- NEW -
				</option>
			</select>


				<Popup
					show={isPopup}
					close={()=>{
						setIsPopup(false);
						setName("");
						setToken("");
					}
					}
				>
					<h1>Add custom Auth</h1>
					<label>Name:</label>
					<input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
					<br/>
					<label>Token:</label>
					<input type="text" value={token} onChange={(e)=>{setToken(e.target.value)}} />
					<br/>

					<button onClick={() => {
							dispatch(userAddKeychainAction(name,token))
							setIsPopup(false);
							setName("");
							setToken("");
							dispatch(userChangeKeychainAction(keychain?.accesses?.length))
					}}>
						Add
					</button>

				</Popup>

			<AccountMenu user={user} onLogout={onLogout} />
		</>
	);
};
