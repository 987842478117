import { FunctionComponent } from "react";
import ApiContext from "../../context/api";

import Login from "./Login";

import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

interface MainAppProps {
  basename: string;
  overseer: any;
  api: any;
  auth: string;
}

const MainApp: FunctionComponent<MainAppProps> = (props) => {
  return (
    //@ts-ignore
    <ApiContext.Provider value={props.api}>
      <Provider store={props.overseer.store}>
        <BrowserRouter basename={`/${props.basename}`}>

          <Login loginMode={
						//@ts-ignore
						props.loginMode
						}
						auth={props.auth}
					/>
        </BrowserRouter>
      </Provider>
    </ApiContext.Provider>
  );
};

export default MainApp;
