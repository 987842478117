import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ForgotPassword.scss";

interface ForgotPasswordProps {
  email?: string | undefined;
  onSubmit: (email: string) => void;
}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = (props) => {
  const [email, setEmail] = useState(props.email);
  let history = useHistory();
  return (
    <div className={"forgotPassword"}>
      <h1>FORGOT PASSWORD</h1>
      <input
        id="email"
        name="email"
        type="email"
        placeholder={"Email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <button className={"forgotButton"} onClick={() => props.onSubmit(email)}>
        Send email
      </button>
      <hr />
      <div
        onClick={() => {
          history.goBack();
        }}
      >
        Go back
      </div>
    </div>
  );
};

export default ForgotPassword;
