import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ResetPassword.scss";

interface ResetPasswordProps {
  email?: string | undefined;
  onSubmit: (email: string) => void;
}

const ResetPassword: FunctionComponent<ResetPasswordProps> = (props) => {
  const [password, setPassword] = useState("");
  let history = useHistory();
  return (
    <div className={"resetPassword"}>
      <h1>RESET PASSWORD</h1>
      <input
        id="password"
        name="password"
        type="password"
        placeholder={"password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <br />

      <button
        className={"resetPasswordButton"}
        onClick={() => props.onSubmit(password)}
      >
        Reset
      </button>
      <hr />
      <div
        onClick={() => {
          history.goBack();
        }}
      >
        Go back
      </div>
    </div>
  );
};

export default ResetPassword;
