import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Signup.scss";

interface SignupFormProps {
  signup: (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => void;
}

const SignupForm: FunctionComponent<SignupFormProps> = (props) => {
  let history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  console.log(props);
  return (
    <div className={"signup"}>
      <h1>SIGN UP</h1>

      <input
        id="firstName"
        name="firstName"
        type="text"
        placeholder={"First name"}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        style={{ width: "123px" }}
      />
      <input
        id="lastName"
        name="lastName"
        type="text"
        placeholder={"Last name"}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        style={{ width: "123px" }}
      />
      <input
        id="email"
        name="email"
        type="email"
        placeholder={"Email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <input
        id="password"
        name="password"
        type="password"
        placeholder={"Password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button
        className={"signupButton"}
        onClick={() => {
          console.log(props.signup);
          props.signup(firstName, lastName, email, password);
        }}
      >
        Sign Up
      </button>

      <hr />

      <div
        onClick={() => {
          history.goBack();
        }}
      >
        Go back
      </div>
    </div>
  );
};
export default SignupForm;
